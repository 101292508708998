import * as React from 'react'

import { HTMLChakraProps, chakra } from '@chakra-ui/react'

export const Logo = (props: HTMLChakraProps<'svg'>) => (
  <chakra.svg
 id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 507 166.96" height="100%"
    {...props}
  >
<g clip-path="url(#clip0_22_126)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M30.24 64.54C37.98 64.4867 44.1967 62.82 48.89 59.54C51.1496 58.0315 52.9951 55.9809 54.2581 53.5755C55.5211 51.1701 56.1612 48.4865 56.12 45.77V45.59C56.2154 43.2733 55.7248 40.9701 54.6935 38.8934C53.6621 36.8168 52.1235 35.0339 50.22 33.71C46.3 30.9033 40.5033 28.7367 32.83 27.21C26.0433 25.87 21.4333 24.4833 19 23.05C16.5667 21.6167 15.4367 19.7267 15.61 17.38V17.2C15.6379 16.1297 15.938 15.0842 16.4822 14.1621C17.0263 13.24 17.7965 12.4718 18.72 11.93C20.72 10.54 23.66 9.81002 27.42 9.76002C30.7804 9.77779 34.1162 10.3349 37.3 11.41C40.7582 12.5894 44.0515 14.2058 47.1 16.22L54 7.88002C50.3327 5.30352 46.2833 3.31932 42 2.00002C37.3445 0.615325 32.5067 -0.0589317 27.65 1.58525e-05C20.3233 0.0933492 14.3967 1.78002 9.87 5.06002C7.72698 6.51792 5.97022 8.47462 4.75086 10.7618C3.53149 13.049 2.88603 15.5982 2.87 18.19V18.37C2.95667 24.01 5.05667 28.1767 9.17 30.87C13.2833 33.5633 19.2267 35.6433 27 37.11C33.58 38.41 37.9933 39.7833 40.24 41.23C42.4867 42.6767 43.54 44.5 43.4 46.7V46.88C43.3815 48.0582 43.0493 49.2101 42.4376 50.2173C41.8259 51.2244 40.9569 52.0503 39.92 52.61C37.6467 54.0233 34.5233 54.7433 30.55 54.77C26.3785 54.8103 22.2345 54.092 18.32 52.65C14.4979 51.1702 10.908 49.1496 7.66 46.65L0 54.51C4.27993 57.7966 9.08881 60.3296 14.22 62C19.3913 63.6919 24.799 64.5493 30.24 64.54V64.54Z" fill="#233787"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M480.17 64.54C487.91 64.4867 494.13 62.82 498.83 59.54C501.088 58.0296 502.932 55.9785 504.194 53.5736C505.457 51.1686 506.098 48.4861 506.06 45.77V45.59C506.153 43.2738 505.661 40.9714 504.63 38.8953C503.599 36.8191 502.061 35.0359 500.16 33.71C496.24 30.9033 490.44 28.7367 482.76 27.21C475.973 25.87 471.387 24.4833 469 23.05C466.613 21.6167 465.483 19.7267 465.61 17.38V17.2C465.64 16.1301 465.941 15.0853 466.485 14.1635C467.029 13.2417 467.798 12.4732 468.72 11.93C470.77 10.54 473.67 9.81001 477.42 9.76001C480.784 9.77775 484.123 10.3348 487.31 11.41C490.768 12.5894 494.062 14.2058 497.11 16.22L503.93 7.88001C500.267 5.30244 496.221 3.31811 491.94 2.00001C487.281 0.613573 482.44 -0.060711 477.58 1.02336e-05C470.247 0.0933436 464.323 1.78001 459.81 5.06001C457.667 6.51791 455.91 8.47462 454.691 10.7618C453.471 13.049 452.826 15.5981 452.81 18.19V18.37C452.85 24.01 454.94 28.1767 459.08 30.87C463.22 33.5633 469.193 35.6433 477 37.11C483.58 38.41 487.993 39.7833 490.24 41.23C492.487 42.6767 493.53 44.5 493.37 46.7V46.88C493.353 48.0574 493.023 49.2091 492.413 50.2163C491.803 51.2235 490.936 52.0498 489.9 52.61C487.627 54.0233 484.5 54.7433 480.52 54.77C476.352 54.8104 472.211 54.0921 468.3 52.65C464.475 51.1703 460.882 49.1498 457.63 46.65L449.98 54.54C454.25 57.812 459.045 60.3346 464.16 62C469.328 63.6918 474.732 64.5492 480.17 64.54V64.54Z" fill="#233787"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M213.63 0.670013H202.44L172.31 63.86H186.08L207.83 15.64L229.68 63.86H243.76L213.63 0.670013Z" fill="#233787"/>
<path d="M88.64 11.11V1.25002H101.37V11.11H88.64ZM111.64 63.64V0.900024H147.47V11.11H124.47V63.64H111.64Z" fill="#233787"/>
<path d="M268.61 11.11V1.25002H281.34V11.11H268.61ZM291.61 63.64V0.900024H327.43V11.11H304.43V63.64H291.61Z" fill="#233787"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M354.62 63.64H369.21L388.37 39.89L396.14 31.55L421.4 0.900024H406.8L388.68 23.39L370.67 0.900024H355.65L381.02 31.73L354.62 63.64Z" fill="#233787"/>
<path d="M127.67 114.88C125.977 114.897 124.299 114.569 122.736 113.918C121.173 113.266 119.76 112.304 118.58 111.09C117.433 109.896 116.529 108.49 115.92 106.95C115.261 105.327 114.922 103.592 114.92 101.84V101.77C114.922 100.031 115.262 98.3095 115.92 96.7C116.534 95.1506 117.436 93.7321 118.58 92.52C119.73 91.3137 121.11 90.3512 122.64 89.69C124.266 89.0058 126.016 88.6653 127.78 88.69C128.83 88.6814 129.879 88.7785 130.91 88.98C131.8 89.1611 132.668 89.436 133.5 89.8C134.277 90.1215 135.017 90.5237 135.71 91C136.395 91.5079 137.046 92.059 137.66 92.65L135.71 94.75C134.661 93.7629 133.484 92.922 132.21 92.25C130.337 91.3069 128.21 90.9921 126.144 91.3524C124.078 91.7126 122.183 92.7286 120.74 94.25C119.848 95.2131 119.145 96.3361 118.67 97.56C118.172 98.8623 117.922 100.246 117.93 101.64V101.71C117.92 103.114 118.171 104.508 118.67 105.82C119.143 107.045 119.845 108.169 120.74 109.13C121.619 110.071 122.676 110.829 123.85 111.36C125.074 111.906 126.4 112.182 127.74 112.17C129.295 112.199 130.834 111.856 132.23 111.17C133.591 110.464 134.839 109.557 135.93 108.48L137.81 110.32C137.178 110.985 136.492 111.597 135.76 112.15C135.046 112.698 134.275 113.168 133.46 113.55C132.613 113.939 131.728 114.241 130.82 114.45C129.79 114.714 128.733 114.858 127.67 114.88Z" fill="#233787"/>
<path d="M169.78 114.88C167.973 114.92 166.177 114.579 164.51 113.88C162.986 113.216 161.609 112.255 160.46 111.053C159.312 109.852 158.414 108.433 157.82 106.88C157.196 105.287 156.881 103.591 156.89 101.88V101.81C156.888 100.099 157.207 98.4034 157.83 96.81C158.433 95.2573 159.333 93.8373 160.48 92.63C161.64 91.4145 163.03 90.4423 164.57 89.77C166.154 89.0364 167.872 88.6361 169.617 88.594C171.362 88.5519 173.097 88.8689 174.715 89.5253C176.332 90.1816 177.797 91.1634 179.02 92.4097C180.242 93.6559 181.195 95.1401 181.82 96.77C182.444 98.3628 182.759 100.059 182.75 101.77V101.84C182.753 103.552 182.431 105.249 181.8 106.84C181.197 108.394 180.292 109.813 179.137 111.015C177.982 112.217 176.599 113.177 175.07 113.84C173.4 114.557 171.597 114.911 169.78 114.88V114.88ZM169.85 112.23C171.225 112.243 172.588 111.967 173.85 111.42C175.034 110.897 176.099 110.139 176.98 109.19C177.846 108.228 178.525 107.112 178.98 105.9C179.487 104.607 179.741 103.229 179.73 101.84V101.77C179.746 100.379 179.498 98.9983 179 97.7C178.537 96.4717 177.834 95.3477 176.933 94.3932C176.032 93.4387 174.95 92.6728 173.75 92.14C172.494 91.5719 171.129 91.2852 169.75 91.3C168.374 91.2886 167.011 91.568 165.75 92.12C164.562 92.6365 163.496 93.3962 162.62 94.35C161.74 95.3109 161.045 96.4263 160.57 97.64C160.071 98.9351 159.82 100.312 159.83 101.7V101.77C159.821 103.161 160.072 104.541 160.57 105.84C161.045 107.072 161.754 108.199 162.66 109.16C163.556 110.107 164.63 110.868 165.82 111.4C167.086 111.968 168.462 112.251 169.85 112.23V112.23Z" fill="#233787"/>
<path d="M204 89.1H206.68L222.68 109.41V89.1H225.47V114.44H223.19L206.79 93.66V114.44H204V89.1Z" fill="#233787"/>
<path d="M247.81 89.1H250.49L266.49 109.41V89.1H269.28V114.44H267L250.6 93.66V114.44H247.81V89.1Z" fill="#233787"/>
<path d="M291.63 89.1H310V91.7H294.49V100.36H308.32V103H294.49V111.88H310.13V114.48H291.63V89.1Z" fill="#233787"/>
<path d="M342.68 114.88C340.988 114.898 339.311 114.571 337.75 113.919C336.189 113.268 334.777 112.305 333.6 111.09C332.446 109.899 331.539 108.492 330.93 106.95C330.271 105.327 329.932 103.592 329.93 101.84V101.77C329.932 100.031 330.272 98.3095 330.93 96.7C331.543 95.1482 332.45 93.7291 333.6 92.52C334.73 91.3173 336.09 90.3548 337.6 89.69C339.226 89.0058 340.976 88.6653 342.74 88.69C343.79 88.6809 344.839 88.7781 345.87 88.98C346.76 89.1597 347.629 89.4346 348.46 89.8C349.255 90.1157 350.013 90.5181 350.72 91C351.421 91.5065 352.089 92.0576 352.72 92.65L350.72 94.75C349.674 93.7637 348.5 92.9228 347.23 92.25C345.355 91.3061 343.227 90.9909 341.159 91.3511C339.092 91.7114 337.195 92.7278 335.75 94.25C334.857 95.2109 334.158 96.3347 333.69 97.56C333.183 98.8601 332.929 100.245 332.94 101.64V101.71C332.928 103.115 333.183 104.51 333.69 105.82C334.156 107.046 334.855 108.171 335.75 109.13C336.629 110.071 337.686 110.829 338.86 111.36C340.087 111.906 341.417 112.182 342.76 112.17C344.315 112.197 345.854 111.854 347.25 111.17C348.607 110.461 349.85 109.555 350.94 108.48L352.82 110.32C352.202 110.981 351.533 111.593 350.82 112.15C350.106 112.698 349.335 113.168 348.52 113.55C347.67 113.941 346.782 114.242 345.87 114.45C344.827 114.717 343.756 114.861 342.68 114.88V114.88Z" fill="#233787"/>
<path d="M379.73 91.74H371.22V89.1H391.1V91.74H382.59V114.44H379.73V91.74Z" fill="#233787"/>
</g>
<defs>
<clipPath id="clip0_22_126">
<rect width="506.06" height="114.88" fill="white"/>
</clipPath>
</defs>
  </chakra.svg>
)